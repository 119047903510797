const INFO = {
	main: {
		title: "Paul Combaldieu | Full-stack developer portfolio",
		name: "Paul Combaldieu",
		email: "paul.combldieu@gmail.com",
		logo: "../logo.png",
	},
	
	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/PaulCombal",
		linkedin: "https://linkedin.com/in/paul-combaldieu",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/users/3001271/yhu420",
		facebook: "https://facebook.com/",
	},
	
	homepage: {
		title: "Full-stack web and mobile app development, just how you imagined.",
		description:
		"I am a French backend developer with expertise in various technologies. During my professional journey, I gained experience in building scalable, secure and reliable web applications using various frameworks and technologies. I am now looking for a new challenge in development, as a full-remote developer for meaningful projects.",
	},
	
	about: {
		title: "A one-person team on a human scale.",
		description:
		"I was born with the dream of understanding how computers could possibly work and how I could make them work the way I wanted them to. With that came interest, passion, and I eventually turned my passion into my job. Over the past few years, I have had the opportunity to study and graduate for a degree in engineering while working as a developer for various startups in different fields. I listened to my gut and moved into project management and consulting services for larger global companies, where I gained amazing human experience for 3 years. Now that that is behind me, I have decided to take on a technical challenge again, remotely. I am currently available for hire or to take on freelance projects, in any case feel free to reach out via any social platform or email.",
	},
	
	articles: {
		title: "There might not be much here, but wait, this section will grow with my experience!",
		description:
		"Chronlogical colleoction of my long-form thoughts, my realisations, and more.",
	},
	
	projects: [
		{
			title: "IT Consultant",
			description:
			"External resource within an international retail firm, focused on application and data migration to cloud technologies.",
			logo: "./suit.png",
			linkText: "View on Linkedin",
			link: "https://www.linkedin.com/in/paul-combaldieu",
		},
		
		{
			title: "Innovation project manager",
			description:
			"Research and development around new & emerging technologies of interest. Introduction of new tools in business processes.",
			logo: "./innovation.png",
			linkText: "View on Linkedin",
			link: "https://www.linkedin.com/in/paul-combaldieu",
		},
		
		{
			title: "Fullstack web developer",
			description:
			"Completing a team of young and ambitious entrepreneurs, creating their startup in the sports domain.",
			logo: "./data.png",
			linkText: "View on Linkedin",
			link: "https://www.linkedin.com/in/paul-combaldieu",
		},
		
		{
			title: "Fullstack web developer",
			description:
			"Not my first work experience, but the first time I faced real-world technical challenges, working surrounded by professionals.",
			logo: "./data.png",
			linkText: "View on Linkedin",
			link: "https://www.linkedin.com/in/paul-combaldieu",
		},
		
		{
			title: "Software & open-source projects",
			description:
			"Projects that stemmed out of passion for clean open-source code and brain-heating challenges.",
			logo: "./programmer.png",
			linkText: "View on Github",
			link: "https://github.com/PaulCombal",
		},
	],
};

export default INFO;
